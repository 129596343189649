function getNavSource(
  navHTML: string | null,
  ssg: boolean
): { navScript: string | undefined; navStyle: string | undefined } {
  let navStyle;
  let navScript;
  const isClient = typeof window !== 'undefined' || ssg;
  if (isClient && navHTML) {
    const regexScript = /src="(.*?)"/;
    const scriptTagMatch = regexScript.exec(navHTML);
    if (scriptTagMatch) {
      // eslint-disable-next-line prefer-destructuring
      navScript = scriptTagMatch[1];
    }
    const regexStyle = /href="(.*?)"/;
    const styleTagMatch = regexStyle.exec(navHTML);
    if (styleTagMatch) {
      // eslint-disable-next-line prefer-destructuring
      navStyle = styleTagMatch[1];
    }
  }

  return {
    navScript,
    navStyle,
  };
}

export { getNavSource };
