import experimentFlagsHelper from '@zola-helpers/client/dist/es/util/experimentFlagsHelper';
import experimentsFlagsHelperSSR from '@zola-helpers/server/dist/es/experiments';

// Experiment Keys
// export const EXAMPLE_FLAG = 'example-flag'; // https://newamsterdamlabs.atlassian.net/browse/CE-1147

// Seasonal flags
export const HOLIDAY_CARDS = 'holiday-cards';
export const HOLIDAY_SHOPS = 'holiday-shops';
export const NEW_PAPER_TYPE_NAPKINS = 'new-paper-type-napkins'; // https://newamsterdamlabs.atlassian.net/browse/PE-1342
export const NEW_PAPER_TYPE_SIGNAGE = 'new-paper-type-signage'; // https://newamsterdamlabs.atlassian.net/browse/PE-1344
export const NEW_PAPER_TYPE_STICKERS = 'new-paper-type-stickers'; // https://newamsterdamlabs.atlassian.net/browse/PE-1343

const FALLBACK_VARIATIONS = {
  [HOLIDAY_CARDS]: experimentFlagsHelper.createExperimentFlags(),
  [HOLIDAY_SHOPS]: experimentFlagsHelper.createExperimentFlags(),
  [NEW_PAPER_TYPE_NAPKINS]: experimentFlagsHelper.createExperimentFlags(),
  [NEW_PAPER_TYPE_SIGNAGE]: experimentFlagsHelper.createExperimentFlags(),
  [NEW_PAPER_TYPE_STICKERS]: experimentFlagsHelper.createExperimentFlags(),
};

export default experimentFlagsHelper.makeExperimentFlags(FALLBACK_VARIATIONS);

const FALLBACK_VARIATIONS_SSR = {
  // [EXAMPLE_FLAG]: experimentFlagsHelper.createExperimentFlags(),
};

// ts-prune-ignore-next
export const experimentFlagsSSR =
  experimentsFlagsHelperSSR.makeExperimentFlags(FALLBACK_VARIATIONS_SSR);
